/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
`

const Item = styled.div``

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Wrapper>
      <Item style={{ flex: "1" }}>
        <main>{children} </main>
      </Item>
      <Item>
        <footer s>
          <p>
            © {new Date().getFullYear()}, Veracity Asset Transformation Service
          </p>
          <p>
            Veracity Asset Transformation Service Limited is registered in
            England and Wales with company number 07468932. Registered office is
            located at Stonyroyd House, 8 Cumberland Road, Leeds, West
            Yorkshire, LS6 2EF.
          </p>
        </footer>
      </Item>
    </Wrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
